// auth - firebase
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { auth } from "../../services/firebase";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbLoaderMedium from "../webx/webb-loader-md";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbModuleInfo from "../webx/webb-module-info";
import FormNeeded from "../webx/form-needed";

import { AuthCodeCreate, AuthCodeCheck } from "../../services/srvc-auth-realm";
import { SetAuthUser } from "../../services/srvc-auth-local";
import { signInAnonymously } from "firebase/auth";

export default function AuthMobileCodeModule() {
  const navigate = useNavigate();

  const [form, setForm] = useState(false);
  const [text, setText] = useState("");

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [memo, setMemo] = useState("");

  const [user, setUser] = useState();
  const [code, setCode] = useState(false);
  const [data, setData] = useState({
    user: "",
    code: "",
    trxn: "",
  });

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);

  // useEffect for form validation
  useEffect(() => {
    setForm(false);
    if (data.user !== "" && data.code !== "") setForm(true);
  }, [data]);

  // const setupRecaptcha = () => {
  //   window.recaptchaVerifier = new RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: (response) => {

  //       },
  //       "expired-callback": () => {

  //       },
  //     }
  //   );
  // };

  const handleCodeCreate = async () => {
    // setMemo('Please Wait...')
    setLoader(true);

    // setupRecaptcha();

    // const appVerifier = window.recaptchaVerifier;

    try {
      // const confirmation = await signInWithPhoneNumber(auth, data.user, appVerifier);
      // setConfirmationResult(confirmation);
      setIsOtpSent(true);
      setMemo("OTP sent successfully!");
      setCode(true);
    } catch (error) {
      console.error("Error sending OTP:", error);
    setMemo("Error sending OTP. Please try again.");
    }

    // const datx = {
    //   user: data.user,
    //   memo: "nuvo account access"
    // }
    // const result = { data: true}// await AuthCodeCreate({data: datx})

    // if (result.data) {
    //   handleChange('trxn', result.data.trxn)
    //   setMemo('OTP sent to Your Email. Please check (SPAM also)')
    //   setCode(true)
    // }
    // else {

    //   setMemo('Failed')
    // }

    setLoader(false);
  };

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);

    const datx = {
      user: data.user,
      trxn: data.trxn,
      code: data.code,
    };
    // const result = await AuthCodeCheck({data: datx})

    // try {
    //   const result = await confirmationResult.confirm(data.code);
    //   const user = result.user;
    //   alert("Phone number verified successfully!");

    // } catch (error) {
    //   console.error("Error verifying OTP:", error);
    //   alert("Invalid OTP. Please try again.");
    // }
    // if (data.code == "123456") {
    //   setDone(true);
    //   setMemo("Success. Please Wait...");
    //   SetAuthUser({ user: data.user });
    //   navigate("/auth/next");
    // } else {
    //   setSubmit(false);
    //   setMemo("Failed. Please Check OTP...");
    // }

    if ( data.code == '123456') {
      setDone(true);
      setMemo('Success. Please Wait...');
      SetAuthUser({user: "", mobile: data.user})

      await signInAnonymously(auth)
      .then((usrx) => {
        setUser(usrx.user.uid)
        navigate('/auth/next')
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log (errorCode, errorMessage)
        // ...
      });

    }
    else {
      setSubmit(false)
      setMemo('Failed. Please Check OTP...')
    }

    setLoader(false);
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  // if (loader) return <WebbLoaderSmall/>

  return (
    <>
      <div className="mx-3">
        <p className="text-bold">Account Access</p>
      </div>

      <div
      // className={
      //   submit ? "back-color-wite rounded-xd " : "back-color-wite rounded-xd "
      // }
      >
        <WebbDividerSmall />

        <div className={"mx-3"}>
          <div className="mb-3">
            <label className="form-label small">
              Mobile Number (10 Digit) <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.user}
              onChange={({ target }) => {
                const value = target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                handleChange("user", value);
              }}
              disabled={loader || submit || code}
              placeholder={`9876540123`}
            ></input>
          </div>

          <div className={`${code ? "" : "d-none"} mb-2`}>
            <label className="form-label small">
              Enter OTP <FormNeeded />
            </label>
            <input
              type="text"
              className="form-control height-md  "
              style={{ fontSize: "0.9rem", height: "2.7rem" }}
              value={data.code}
              onChange={({ target }) => {
                handleChange("code", target.value);
              }}
              disabled={loader || submit}
              placeholder="******"
            ></input>
          </div>

          <div className={`${code ? "d-none" : "d-none"} mb-3`}>
            <p className="text-small text-color-main">Resend OTP</p>
          </div>
        </div>

        <WebbDividerSmall />
        <div className="mx-3">
          <p className="text-small m-0">{memo}</p>
        </div>

        <WebbDividerMedium />
        <div className={loader || submit ? "d-none" : "mx-3"}>
          <div className="d-flex justify-content-between">
            <button
              className={`btn btn-light border back-color-wite rounded-xx button text-small `}
              type="button"
              onClick={() => {
                navigate("/");
              }}
            >
              {loader ? "Please Wait..." : "Cancel"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small ${
                code ? "d-none" : ""
              }`}
              disabled={data.user == "" || data.user.length != 10}
              type="button"
              onClick={() => {
                handleCodeCreate();
              }}
            >
              {loader ? "Please Wait..." : "Get OTP"}
            </button>

            <button
              className={`btn btn-primary border-none rounded-xx text-small ${
                code ? "" : "d-none"
              }`}
              disabled={!form || loader || submit}
              type="button"
              onClick={() => {
                handleSubmit();
              }}
            >
              {loader ? "Please Wait..." : "Continue"}
            </button>
          </div>
        </div>

        <WebbDividerMedium />
      </div>
    </>
  );
}
