// assets
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import Slider from "react-slick";

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'


import { AssetsDiscoverList } from '../../services/srvc-assets-realm'


import listStatus from '../../data.static/data-transfers-status.json'
import { MarketOfferList } from '../../services/srvc-assets-discover-realm';

export default function DiscoverListMarketOfferModule (props) {
  // console.log(props.search)

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState(listStatus.data)

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "24px",    
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 780,
        settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 2, infinite: true, },
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 }
      }
    ],    
    // afterChange: function(index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // } 
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)

        const datx = {
          user: asset.item, 
          // domain: 'social',
          // index: index, items: items, 
          // filters: { name: props.search }
        }
        const result = await MarketOfferList({
          data: datx,
          srvc: "******",
          type: "social",
        });
        //console.log('mm', result)

        if (result.stat) {
          // && resAssets.stat
          setData(result.data.list)

          const total = result.data.count
          setTotal(result.data.count)

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < total ? index * items : total
            } of ${total}`
          )
        }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  const handleClick = (item) => {
    const assetx = data.find(x => x.item == item)
    navigate(`/${asset.role}/discover/market/${item}`)
  }

  if (loader) return <>
    <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>

      <span className="align-middle text-lead">
        <i className="bx bxs-info-circle text-color-wait"></i>
      </span>
      <span className='ms-1 text-color-tone'>Please Wait...</span>

    </div>
  </>
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded-xd border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Market Offers</span>
        </div>
      </>
    )

  return (
    <>
      {/* info */}
      <h2 className='text-normal m-0 ms-3'>{'Personalised Offers'}</h2>
      <div className="mb-2"></div>


      {/* data */}
      <div className='d-flex mx-3 mb-2 text-small'>
        <div className=''>Showing 1-{total > items ? items : total} of {total}</div>
        <div className='ms-auto text-end'></div>
      </div>
      
      {/* data-cards-1 */}
      
      {/* <div className={data.length > 1 ? 'd-none' : ''}>
      {data && data.map((item, i) => (
          <div key={i} className='px-1'>
            <div className='media-standard cursor' onClick={() => handleClick(item.item)}>
              <img src={item.media.link} className='rounded-xd' alt=""></img>
              <div className={`btn rounded-wd text-small back-color-${item?.status?.mint && !item?.status?.burn ?'success' : 'error'}`}
                // style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
              > {item?.status?.mint && !item?.status?.burn ?'Active' : 'Inactive'}
              </div>
            </div>
          </div>
        ))}
      </div> */}

      {/* data-cards */}
      <div className={data.length > 1 ? '' : 'd-none'}>
      <Slider {...settings}>
        {data && data.map((item, i) => (
          <div key={i} className='px-1'>
            <div className='media-standard cursor' onClick={() => handleClick(item.item)}>
              <img src={item.medx.link} className='rounded-xd' alt=""></img>
              <div className={`d-none btn rounded-wd text-small back-color-${item?.stat?.mint && !item?.stat?.burn ?'success' : 'error'}`}
                // style={{ backgroundColor: 'rgba(0,0,0,0.75)' }}
              > {item?.stat?.mint && !item?.stat?.burn ? 'Active' : 'Inactive'}
              </div>
              <div className="position-absolute bottom-0 w-100 p-2 bg-black bg-opacity-50 rounded-xd">
                          <div className="p-2 rounded-xd">
                            {/* Large Bold Heading */}
                            <p className="m-0 text-white fw-bold fs-3">
                              {item?.meta?.name || "******"}
                            </p>
                            <p className="m-0 text-white fw-bold fs-3">
                              {item.item || "******"}
                            </p>

                            {/* Larger Memo Text */}
                            <p className="m-0 text-white fs-5 fw-semibold">
                              {item?.meta?.memo || "******"}
                            </p>

                            {/* Larger Rate Display */}
                            <p className="m-0 text-white fs-4 fw-bold">
                              {item?.rate?.nmbr || 0}{" "}
                              {item?.rate?.tick || "INR"}
                            </p>
                            {item?.date?.start > Date.now() && <p className="m-0 text-white fs-6 fw-bold">
                              Coming Soon: Offer starts on {(new Date(item?.date?.start)).toLocaleDateString()} at {(new Date(item?.date?.start)).toLocaleTimeString()}
                            </p> }
                          </div>
                          </div>
            </div>
          </div>
        ))}
      </Slider>
      </div>

    </>
  )
}
