// assets
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import listActions from "../../data.static/data-asset-actions.json";

import {
  AccountsMinterDetails,
  AccountsMinterInit,
  AccountsMinterStatus,
} from "../../services/srvc-accounts-minter-realm";
import { TextSecure, TextReverse } from "../../services/srvc-encr-node";

import {
  TokenClaim,
  TokenTransfer,
} from "../../services/srvc-tokens-rand-realm";
import { AssetOfferCreate } from "../../services/srvc-assets-discover-realm";

import { CredentialStatusMintSet } from "../../services/srvc-credentials-realm";
import { CredentialStatusBurnSet } from "../../services/srvc-credentials-realm";
import { AssetUnitDetails } from "../../services/srvc-assets-realm";

export default function AssetActionsModuleXX(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);

  const [form, setForm] = useState(false);
  const [code, setCode] = useState(false);
  const [check, setCheck] = useState(false);

  const [memo, setMemo] = useState("");

  const [account, setAccount] = useState({});

  const [actions, setActions] = useState([]);
  const [status, setStatus] = useState(props?.data?.status || {});
  const [cred, setCred] = useState(props?.data || {});
  const [data, setData] = useState({
    number: "",
    code: "",
    trxn: "",
    secret: "",
    rate: "",
  });

  const [modalstatus, setmodalstatus] = useState(false);
  const [modalaction, setModalAction] = useState("");

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        var actionx = Array.from(
          listActions.data.filter((x) => x.actv),
          (z) => {
            return { ...z, live: false };
          }
        );
      
        actionx = Array.from(actionx, (x) => {
          var statux = {
            mint: props?.data?.status?.mint
              ? false
              : props?.data?.status?.mint
              ? false
              : true,
            code: props?.data?.status?.burn
              ? false
              : props?.data?.status?.mint
              ? true
              : false,
            sell: props?.data?.status?.burn
              ? false
              : props?.data?.status?.mint && props?.unitDetails?.status?.resl
              ? true
              : false,
            renew: props?.data?.status?.burn
              ? false
              : props?.data?.status?.mint
              ? true
              : false,
            download: [
              "42e3d8a57bbe4838bf75c901b422ed925",
              "34159089ea974acc98ed1c8ea0196ddd7",
            ].includes(props?.data?.taxxon)
              ? true
              : false,
          };

          var activex = {
            mint: true,
            code: true,
            sell:
              (props?.data?.taxxon == "321e4e89a1964f5db496185f0ef0376a3" ||
                props?.data?.taxxon?.substring(0, 2) == "36") &&
              props?.unitDetails?.status?.resl
                ? true
                : false,
            renew:
              props?.data?.taxxon == "315c237f1c84475b8881de209b39a9664"
                ? true
                : false,
            download: [
              "42e3d8a57bbe4838bf75c901b422ed925",
              "34159089ea974acc98ed1c8ea0196ddd7",
            ].includes(props?.data?.taxxon)
              ? true
              : false,
          };

          return { ...x, live: statux[x.code], actv: activex[x.code] };
        });

        setActions(actionx.filter((x) => x.actv));

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [status]);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const accountx = await AccountsMinterDetails({
          data: { user: asset.item },
          srvc: "******",
        });
        // console.log (accountx)
        if (accountx.stat) {
          setAccount(accountx.data.account);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    setForm(false);
    if (data.code !== "" && data.code.length == 6 && !isNaN(data.code))
      setForm(true);
  }, [data.code]);

  const handleClick = (status) => {
    // navigate(`/${asset.role}/assets/${item}`)
  };

  const handleChange = async (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleMint = async () => {
    var datx = { user: asset?.item };
    const minterx = await AccountsMinterDetails({
      data: datx,
      client: "",
      service: "",
    });
    // console.log(minterx)
    // console.log(data.secret)
    // if (minterx.data.account.code !== data.secret) {
    //   setMemo('Failed: Incorrect Account Passcode')
    //   return
    // }
    // else {

    // }

    setLoader(true);
    setSubmit(true);
    // reverse
    // console.log(account)
    const mnemonix = await TextReverse({
      text: account.secret,
      secret: minterx.data.account.code,
    });
    // console.log(mnemonix)

    var datx, result, res, claimx, transferx, mintx;
    // claim
    setMemo("Please Wait... 1/3");
    datx = {
      user: asset?.item || "******",
    };
    var statusx = await AccountsMinterStatus({
      data: datx,
      client: "",
      service: "",
    });
    // console.log(statusx)
    if (!statusx?.data?.status?.mint) {
      // console.log('Please Wait... 3x')
      var initx = await AccountsMinterInit({
        data: datx,
        client: "",
        service: "",
      });
      //  console.log(initx)
      statusx = await AccountsMinterStatus({
        data: datx,
        client: "",
        service: "",
      });
    }

    datx = {
      account: { number: "", secret: mnemonix.data },
      number: cred?.webx?.nmbr,
      asset: cred?.webx?.item,
      count: "1",
      memo: "",
      chain: cred?.webx?.ntwk,
    };
    // console.log (datx)
    claimx = await TokenClaim({ data: datx, srvc: "******" });
    // console.log(claimx)

    // transfer
    setMemo("Please Wait... 2/3");
    datx = {
      debit: { number: "", secret: "" },
      credit: { number: account.number, secret: mnemonix.data },
      number: cred?.webx?.nmbr,
      asset: cred?.webx?.item,
      count: "1",
      memo: "",
      chain: cred?.webx?.ntwk,
    };
    // console.log (datx)
    transferx = await TokenTransfer({ data: datx, srvc: "******" });
    // console.log(transferx)

    setMemo("Please Wait... 3/3");
    if (transferx.stat) {
      datx = { user: asset.item, item: id, status: true };
      mintx = await CredentialStatusMintSet({ data: datx, srvc: "******" });
      // console.log (mintx)
    }
    setMemo("Please Wait... Done");

    if (mintx.stat) {
      setDone(true);
      setMemo("");
      setTimeout(() => {
        props.handleRefresh();
      }, 1000);
    }

    setSubmit(false);
    setLoader(false);

    // modalClose()
  };

  const handleOfferCreate = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please Wait... 0/1");

    var datx, result, res, offerx;

    setMemo("Please Wait... 1/3");
    datx = {
      rate: { number: data.rate, ticker: "inr" },
      user: { name: asset.name, mail: asset.mail, item: asset.item },
      asset: cred?.asset,
      unit: cred?.unit,
      format: cred?.unit?.form,
      cred: { item: id, nmbr: props.data.webx.nmbr },
    };
    // console.log (datx)
    offerx = await AssetOfferCreate({ data: datx, srvc: "******" });
    // console.log(offerx)

    if (offerx.stat) {
      setMemo("Please Wait... Done");
      setDone(true);
      props.setIsoffCreated(true);
    }

    setSubmit(false);
    setLoader(false);
  };

  const modalShow = () => {
    setmodalstatus(true);
  };
  const modalClose = () => {
    setmodalstatus(false);
  };

  // if (loader) return <></>

  return (
    <>
      {/* data */}
      <div className="rounded-xd back-color-wite p-2">
        <div className="mb-2"></div>
        <div className={`row row-cols-${actions.length} g-1`}>
          {actions &&
            actions.map((item, i) => (
              <div className="col text-center" key={i}>
                <div className="d-flex justify-content-center">
                  <div
                    className=""
                    // data-bs-toggle='modal'
                    onClick={() => {
                      if (item.live == true) {
                        setModalAction(item.code);
                        modalShow();
                      }
                     if (item.code === "download") {
                       const downloadUrl = props?.data?.media?.link
                       const link = document.createElement("a");
                       link.href = downloadUrl;
                       link.download = props?.data?.media?.link; // Set the desired file name
                       document.body.appendChild(link);
                       link.click();
                       document.body.removeChild(link);
                     }
                    }}
                    // data-bs-target={item.live ? `#${item.code}` : `#x`}
                  >
                    <i
                      className={`${
                        item.icon
                      } text-icon-sm  back-color-lite p-2 rounded-xx  ${
                        item.live ? "cursor hidark" : "text-color-tone"
                      }`}
                      style={{ width: "3rem", height: "3rem" }}
                    ></i>
                  </div>
                </div>
                <div className="mt-1">
                  <p
                    className={`text-mini text-uppercase text-truncate m-0 ${
                      item.live ? "" : "text-color-tone"
                    }`}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* <ModalViewAsetAction isOpen={modalstatus} onClose={modalClose} handleOnResult={handleOnResult} cred={cred} type={modalaction} ></ModalViewAsetAction> */}

      <div className={modalstatus && modalaction == "mint" ? "" : "d-none"}>
        <div className="modal-overlay" id="mint">
          <div className="modal-content rounded-xd">
            {/* modal header */}
            <div className="p-3 m-0 d-none">
              <div className="header text-bold m-0"></div>
            </div>

            {/* modal content */}
            <div className="border-none scrollbar">
              {" "}
              {/* style={{overflowY:'scroll', height:'auto'}} */}
              <div className="border-none p-3 mx-3 rounded-xd">
                <div className="">
                  <p className="text-normal text-center m-0">{"Mint Asset"}</p>
                  <WebbDividerSmall />
                  <div className="">
                    <div className="mb-2"></div>
                    <div className="media-banner">
                      <img
                        src={props.data?.media?.link}
                        className="w-100 rounded-xd"
                        alt="..."
                      ></img>
                    </div>
                  </div>

                  <WebbDividerSmall />
                  <p className="text-normal text-center text-bold m-0 text-truncate">
                    {cred?.meta?.name || "******"}
                  </p>
                  <p className="text-normal text-center m-0">
                    {(cred?.number || "0000000000000000")
                      .match(/.{1,4}/g)
                      .join(" ")}
                  </p>
                </div>

                <WebbDividerSmall />
                {/* <div className={done? 'd-none' : 'mb-3 text-center'}>
                <label className="form-label small">Enter Account Code and Submit <FormNeeded/></label>
                <input type="text" className="form-control height-md text-center"
                  style={{fontSize:'0.9rem', height:'2.7rem'}}
                  value={data.secret}
                  onChange={({ target }) => {handleChange("secret", target.value); setMemo('') }}
                  disabled={loader || submit}
                  placeholder="******"
                >
                </input>
              </div> */}

                {/* <WebbDividerSmall/> */}
                <div className={""}>
                  <p className="text-small m-0 fw-bold text-danger text-center">
                    {memo}
                  </p>
                  {/* <div className="mb-2"></div> */}

                  <WebbDividerMedium />
                  <div
                    className="progress d-none "
                    role="progressbar"
                    aria-label="..."
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%", height: "0.5rem" }}
                  >
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      style={{ width: "100%", height: "0.5rem" }}
                    ></div>
                  </div>
                </div>

                {/* action */}
                <div className={loader || submit || done ? "d-none" : ""}>
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx w-100"
                    onClick={() => handleMint()}
                    disabled={loader || submit}
                  >
                    Mint Now
                  </button>

                  <div className="mb-1"></div>
                  <button
                    className="btn btn-outline-secondary  border btn-sm text-small rounded-xx w-100"
                    onClick={() => modalClose()}
                  >
                    Cancel
                  </button>
                </div>

                <div className={!loader && done ? "text-center" : "d-none"}>
                  <p className="m-0">success: asset minted</p>

                  <WebbDividerSmall />
                  <button
                    className="btn btn-outline-secondary border btn-sm text-small rounded-xx w-100"
                    onClick={() => modalClose()}
                  >
                    Close
                  </button>
                </div>

                <WebbDividerSmall />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={modalstatus && modalaction == "code" ? "" : "d-none"}>
        <div className="modal-overlay" id="">
          <div className="modal-content rounded-xd">
            {/* header */}
            <div className="p-3 m-0 text-center d-none">
              <div className="header text-bold m-0">Quick Share</div>
            </div>

            {/* content */}
            <div className="border-none scrollbar">
              <WebbDividerSmall />
              <div className="text-center">
                <p className="text-lead text-bold m-0">
                  {cred?.meta?.name || "******"}
                </p>
                <p className="text-normal m-0">
                  {(cred?.webx?.nmbr || "0000000000000000")
                    .match(/.{1,4}/g)
                    .join(" ")}
                </p>
              </div>
              <WebbDividerSmall />
              <div className="text-center p-3 rounded-xd">
                <div className="p-3">
                  <QRCodeSVG
                    value={`iam.user.${cred.item}`}
                    size={""}
                    includeMargin={false}
                  />
                </div>
              </div>
            </div>

            {/* action */}
            <div className={"p-3"}>
              <WebbDividerSmall />
              <button
                className="btn btn-outline-secondary border btn-sm text-small rounded-xx w-100"
                onClick={() => modalClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={modalstatus && modalaction == "sell" ? "" : "d-none"}>
        <div className="modal-overlay" id="">
          <div className="modal-content rounded-xd p-3 mx-3">
            {/* header */}
            <div className="text-center">
              <p className="m-0">{"Create Offer"}</p>
              <WebbDividerSmall />
              <div className="text-center">
                <p className="text-lead text-bold m-0">
                  {cred?.meta?.name || "******"}
                </p>
                <p className="text-normal m-0">
                  {(cred?.number || "0000000000000000")
                    .match(/.{1,4}/g)
                    .join(" ")}
                </p>
              </div>
            </div>

            {/* content */}
            <div className="border-none scrollbar">
              <div className="text-center rounded-xd">
                <WebbDividerSmall />
                <div className={done ? "d-none" : "mb-3 text-center"}>
                  <label className="form-label small">
                    Enter Offer (Sale Amount) <FormNeeded />
                  </label>
                  <input
                    type="text"
                    className="form-control height-md text-center"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    pattern="[1-9][0-9]*"
                    value={data.rate}
                    onChange={({ target }) => {
                      handleChange(
                        "rate",
                        target.validity.valid ? target.value : data.rate
                      );
                    }}
                    disabled={loader || submit}
                    placeholder=""
                  ></input>
                </div>
              </div>

              <WebbDividerSmall />
            </div>

            {/* status bar */}
            <div className={loader ? "" : "d-none"}>
              <WebbDividerSmall />
              <p className="text-small m-0">{memo}</p>
              <div className="mb-2"></div>
              <div
                className="progress"
                role="progressbar"
                aria-label="..."
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%", height: "0.5rem" }}
              >
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated"
                  style={{ width: "100%", height: "0.5rem" }}
                ></div>
              </div>
            </div>

            {/* action */}
            <div className={loader || submit || done ? "d-none" : ""}>
              <button
                className="btn btn-primary btn-sm text-small rounded-xx w-100"
                onClick={() => handleOfferCreate()}
                disabled={loader || submit || data.rate == "" || data.rate == 0}
              >
                Submit
              </button>

              <div className="mb-1"></div>
              <button
                className="btn btn-outline-secondary  border btn-sm text-small rounded-xx w-100"
                onClick={() => modalClose()}
              >
                Cancel
              </button>
            </div>

            <div className={!loader && done ? "text-center" : "d-none"}>
              <p className="m-0">success: offer created</p>

              <WebbDividerSmall />
              <button
                className="btn btn-outline-secondary border btn-sm text-small rounded-xx w-100"
                onClick={() => modalClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={modalstatus && modalaction == "renew" ? "" : "d-none"}>
        <div className="modal-overlay" id="renew">
          <div className="modal-content rounded-xd">
            {/* modal header */}
            <div className="p-3 m-0 d-none">
              <div className="header text-bold m-0"></div>
            </div>

            {/* modal content */}
            <div className="border-none scrollbar">
              {" "}
              {/* style={{overflowY:'scroll', height:'auto'}} */}
              <div className="border-none p-3 mx-3 rounded-xd">
                <div className="text-center">
                  <p className="text-normal m-0">{"Scan and Verify"}</p>
                  <WebbDividerSmall />

                  <p className="text-normal text-bold m-0 text-truncate">
                    {cred?.meta?.name || "******"}
                  </p>
                  <p className="text-small m-0">{cred?.number || "******"}</p>
                </div>

                <WebbDividerSmall />

                <WebbDividerSmall />

                {/* action */}

                <div className={""}>
                  <button
                    className="btn btn-outline-secondary border btn-sm text-small rounded-xx w-100"
                    onClick={() => modalClose()}
                  >
                    Close
                  </button>
                </div>

                <WebbDividerSmall />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className={!loader && submit && done ? 'mx-3 text-center' : ''}>
<lottie-player 
  src="https://lottie.host/7cb07c60-c842-4943-a696-342032d450b4/hoKLlSMFMn.json" 
  background="##FFFFFF" 
  style={{width: "240px", height: "240px"}} 
  speed="1" autoplay direction="1" mode="normal"
>
</lottie-player>
</div> */
}
