// assets
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NumberFormat } from "../../services/srvc-utilities";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { CredentialsListUserDomain } from "../../services/srvc-credentials-realm";

import listStatus from "../../data.static/data-transfers-status.json";
import { UserDocumentList } from "../../services/srvc-media-docs-realm";

export default function DocumentsListUserModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [list, setList] = useState(listStatus.data);

  const [search, setSearch] = useState();

  const [index, setIndex] = useState(1);
  const [items, setItems] = useState(10);

  const [curr, setCurrentIndex] = useState(1);
  const [next, setNextIndex] = useState();
  const [last, setLastIndex] = useState();

  const [count, setCount] = useState();
  const [total, setTotal] = useState();

  const [text, setText] = useState("");

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "24px",
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    // afterChange: function(index) {
    //   console.log(
    //     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
    //   );
    // }
  };

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await UserDocumentList({
          data: {
            user: asset.item,
            index: index,
            items: items,
          },
        });
        // console.log('r', result)

        if (result.stat) {
          // && resAssets.stat
          setData(result.data.list);

          const resTotal = result.data.count; //+resAssets.data.count
          setTotal(result.data.count);

          setText(
            `${(index - 1) * items + 1} - ${
              index * items < resTotal ? index * items : resTotal
            } of ${resTotal}`
          );
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [props.search, index, items]);

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1);
      setIndex(curr + 1);
      setCurrentIndex(curr + 1);
    }
  };

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1);
      setIndex(curr - 1);
      setCurrentIndex(curr - 1);
    }
  };

  const handleClick = (item) => {
    navigate(`/${asset.role}/documents/${item}`);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-tint"></i>
          </span>
          <span className="ms-1 text-color-tone">No Documents</span>
        </div>
      </>
    );

  return (
    <>
      {/* data-list */}
      <div className="d-flex mx-3 mb-2 text-small d-none">
        <div className="">Showing 1-5 of {total}</div>
        <div className="ms-auto text-end"></div>
      </div>

      <div className="back-color-wite rounded-xd p-2">
        {data &&
          data.map((item, i) => (
            <div className="" key={i}>
              <div
                className="d-flex p-1 rounded-xd cursor"
                onClick={() => handleClick(item?.item)}
              >
                <div className="" style={{ width: "4.2rem", height: "4.2rem" }}>
                  <div className="media-cube">
                    {item?.file?.mime.includes("pdf") ? (
                      <i class="bx bxs-file-pdf bx-lg"></i>
                    ) : (
                      <img
                        src={item?.file?.link}
                        className="rounded-xd"
                        alt="Image"
                      ></img>
                    )}
                  </div>
                </div>

                <div className="px-1 ms-2">
                  <p className="text-bold m-0">
                    {item?.meta?.name || "******"}
                  </p>
                  <p className="text-small m-0 mb-1">
                    {item?.meta?.number || "******"}
                  </p>
                  {/* <p className='text-small m-0'>{item?.creator?.name || '******'}</p> */}
                </div>

                <div className="ms-auto">
                  <div
                    className={`vr m-2 rounded-xd opacity-75 back-color-${
                      item?.active ? "success" : "error"
                    }`}
                    style={{ width: ".25rem", height: "79%" }}
                  ></div>
                </div>
              </div>

              <div
                className={`d-flex p-1 ${i < data.length - 1 ? "" : "d-none"}`}
              >
                <div className="" style={{ width: "0" }}></div>
                <div className="w-100">
                  <div className="border-bottom"></div>
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* data-cards */}
    </>
  );
}
