// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xw";
import WebbHeader from "../content/webz/webb-header-xw";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import AssetListUserIdentitiModule from "../content/assets/assets-list-identiti";
import AssetListUserAcademicsModule from "../content/assets/assets-list-academics";
import AssetListUserWorkModule from "../content/assets/assets-list-work";
import AssetListUserSocialMembersModule from "../content/assets/assets-list-social-members";
import AssetListUserSocialEventsModule from "../content/assets/assets-list-social-events";
import DocumentsListUserModule from "../content/documents/document-list-user";
import AssetListUserTravelModule from "../content/assets/assets-list-travel";
import AssetListUserAwardsModule from "../content/assets/assets-list-awards";



export default function Assets () {
  
  const metadata = {
    name: 'Assets',
    banner: {link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: "small",
          show: true,
          data: (
            <>
              {" "}
              <WebbHeader
                data={{ name: metadata.name, home: "/", link: "" }}
              />{" "}
            </>
          ),
        }}
        media={{ size: "fluid", show: false, data: <></> }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="mb-2"></div>
              <h2 className="text-normal m-0 ms-3">{"Digital IDs"}</h2>
              <WebbDividerSmall />
              <AssetListUserIdentitiModule />

              <div className="mb-2 mt-4"></div>
              <h2 className="text-normal m-0 ms-3">{"Documents"}</h2>
              <WebbDividerSmall />
              <DocumentsListUserModule />

              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">{"Work & Internships"}</h2>
              <WebbDividerSmall />
              <AssetListUserWorkModule />

              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">{"Events"}</h2>
              <WebbDividerSmall />
              <AssetListUserSocialEventsModule />

              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">{"Memberships (Social)"}</h2>
              <WebbDividerSmall />
              <AssetListUserSocialMembersModule />
              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">{"Awards"}</h2>
              <WebbDividerSmall />
              <AssetListUserAwardsModule />

              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">
                {"Academics & Cerificates"}
              </h2>
              <WebbDividerSmall />
              <AssetListUserAcademicsModule />

              <WebbDividerMedium />
              <h2 className="text-normal m-0 ms-3">{"Travel & Tickets"}</h2>
              <WebbDividerSmall />
              <AssetListUserTravelModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="text-center back-color-lite">
                <WebbFooterMobile />
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}