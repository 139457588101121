// assets
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Lottie from "react-lottie";
import medialottie from "../../data.media/alerts-success.json";

import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import {
  AssetDetails,
  AssetUnitDetails,
} from "../../services/srvc-assets-realm";
import {
  AssetOfferList,
  AssetOfferStatusSet,
} from "../../services/srvc-assets-discover-realm";

import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";
import {
  TransferFundCreate,
  TransferFundSubmit,
  TransfersFundAccountCredit,
  TransfersFundCreate,
  TransfersFundSubmit,
} from "../../services/srvc-transfers-funds-realm";

// user creds
import { CredentialsListUserDomain } from "../../services/srvc-credentials-realm";

import axios from "axios";
import {
  TransfersAssetClaim,
} from "../../services/srvc-transfers-assets-realm";

import {
  IndividualOffersDetails,
  IndividualOffersStatusSet,
} from "../../services/srvc-offers-realm";

export default function OffersDetailsModule(props) {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();
  const { id } = useParams();
  const { od } = useParams();

  const [loader, setLoader] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [memo, setMemo] = useState("...");

  const [data, setData] = useState();

  const [rates, setRates] = useState(); // creator offers
  const [offers, setOffers] = useState(); // user offers

  const [checkoutitem, setCheckoutItem] = useState();

  const [balancestatus, setBalanceStatus] = useState(false);
  const [balance, setBalance] = useState({ number: "0", ticker: "" });


  const [modalstatus, setModalStatus] = useState(false);
  const [action, setAction] = useState("");

  const [orderId, setOrderId] = useState("");
  const [order, setOrder] = useState();

  const [commerce, setCommerce] = useState(false);

  const [modalCommerceStatus, setModalCommerceStatus] = useState(false);
  const [modalCancelTransaction, setModalCancelTransaction] = useState(false);
  const [modalCheckoutStatus, setModalCheckoutStatus] = useState(false);

  const [mintstart, setMintStart] = useState(false);
  const [mintdone, setMintDone] = useState(false);
  const [modalMinterStatus, setModalMinterStatus] = useState(false);

  const [minterstatus, setMinterStatus] = useState(false);
  const [type, setType] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: medialottie,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };

  const [code, setCode] = useState("");

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        window.scrollTo(0, 0);
        setLoader(true);

        var res = await IndividualOffersDetails({
          data: { item: od },
          client: "",
          service: "",
        });

        const result = await AssetUnitDetails({
          data: { item: id, user: asset.item },
          srvc: "******",
          type: res?.data?.unit?.form,
        });

        setType(res?.data?.unit?.form);

        var ratex = [];
        if (res.stat) {
          setData(result.data);
          ratex =
            Array.from(res?.data?.rate, (x) => {
              return {
                ...x,
                active: x.item == "69c214c90838489b9ecb35383bebe94e6",
                status: false,
              };
            }) || [];
        }
      
        var credx = await CredentialsListUserDomain({
          data: {
            user: asset.item,
            domain: "members",
            index: '1',
            items: '99',
            filters: { name: "" },
          },
        });
        if (credx.stat) {
          // setCreds(result.data.list);
        }

        for (let c = 0; c < ratex.length; c++) {
          var index = credx?.data?.list?.findIndex(
            (z) => z.unit.item == ratex[c].item
          );
          if (index > -1) ratex[c] = { ...ratex[c], active: true };
        }

        setRates((rates) => [...ratex.filter((x) => x.active)]);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        const ratex = await AssetOfferList({
          data: { item: id, user: asset.item },
          srvc: "******",
        });
    
        if (ratex.stat)
          setOffers(
            Array.from(ratex.data.list, (x) => {
              return {
                ...x,
                rate: { number: x.rate.nmbr, ticker: x.rate.tick },
              };
            })
          );
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        const result = await AccountsBaseBalance({
          data: { user: asset.item },
          srvc: "******",
        });

        if (result.stat) {
          setBalance(result.data.balance);
        }
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        if (parseInt(balance?.number / 1000000) > checkoutitem?.rate?.number)
          setBalanceStatus(true);
        else setBalanceStatus(false);
      };
      fetchData();
    } else {
    }
  }, [checkoutitem]);

  const handleUserOfferSelect = async (item) => {
    var ratex = Array.from(rates, (x) => {
      return { ...x, status: false };
    });
    var x = ratex.findIndex((z) => z.item == item);
    ratex[x] = { ...ratex[x], status: true };
    setRates((rates) => [...ratex]);

    var checkout = ratex.findIndex((z) => z.status);
    // if (checkout > -1) setCheckoutStatus(true);
  };

  const handleAlternateOfferSelect = async (item) => {
    var ratex = Array.from(offers, (x) => {
      return { ...x, status: false };
    });
    var x = ratex.findIndex((z) => z.user.item == item);
    ratex[x] = { ...ratex[x], status: true };
    setRates((rates) => [...ratex]);

    var checkout = ratex.findIndex((z) => z.status);
    // if (checkout > -1) setCheckoutStatus(true);
  };

  const handleCheckoutUserOffer = async () => {

    setAction("ecommerce");

    var datx = {
      credit: {
        name: asset.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: { name: "account.credit", memo: `account.credit` },
      count: {
        number: rates.find((x) => x.status)?.rate?.nmbr || "0",
        ticker: "INR",
      },
      rate: { number: "1", ticker: "" },
      asset: {},
      unit: {},
    };
    var res = await TransfersFundAccountCredit({
      data: datx,
      client: "",
      service: "",
    });

    // 2 Paymetn Gateway Call
    var res = await handleRazorPayPaymentGateway(res.data.item);

  };

  const handleRazorPayPaymentGateway = async (trxn) => {
    const orderCreate = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/create",
      {
        data: { amount: rates.find((x) => x.status)?.rate?.nmbr },
      }
    );
    const dataSave = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/save",
      {
        data: { ...orderCreate.data._rejectionHandler0, stat: 0, trxn: trxn },
      }
    );

    const orderTx = orderCreate.data._rejectionHandler0;
    setOrder(orderTx);
    setOrderId(orderTx.id);

    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY, // process.env.RAZORPAY_APP_KEY
      amount: orderTx.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name:
        checkoutitem?.feat?.mode == "resale"
          ? checkoutitem.user.name
          : data?.creator?.name,
      description: `${data?.meta?.name} sale`,
      image: "https://example.com/your_logo",
      order_id: orderTx.id,
      handler: (res) => {
        paymentVerificationSave(res);
        return res;
      },
      prefill: {
        name: asset.name,
        email: asset.mail,
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const paymentVerificationSave = async (value) => {
    const res = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/payment/verify",
      { data: value }
    );
    if (res.data.stat) {
      setCommerce(true);

      setTimeout(() => {
        modalClose();
        if (checkoutitem?.feat?.mode == "resale") {
          handleFundTransferUserToSeller(); // resale
        } else {
          handleFundTransferUserToCreator();
        }
        modalCheckoutClose();
      }, 3000);
    }
  };

  const modalTransactionClose = async () => {
    const res = await axios.post(
      "https://ap-south-1.aws.data.mongodb-api.com/app/bharat-transfers-orders-mbizg/endpoint/order/cancel",
      { data: { id: orderId } }
    );
    if (res.data.stat) {
      modalCheckoutClose();
      setModalCancelTransaction(false);
      window.location.reload(true);
    }
  };

  const modalTransactionOpen = () => {
    setModalCancelTransaction(true);
  };

  const modalCheckoutClose = () => {
    setModalCommerceStatus(false);
    setCommerce(false);
    setModalCheckoutStatus(false);
  };

  const ContinueOrderPurchase = async () => {
    const options = {
      key: "rzp_test_Vpjr5HSfP3SBgV", // process.env.RAZORPAY_APP_KEY
      amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: asset.name,
      description: "account.credit",
      image: "https://example.com/your_logo",
      order_id: order.id,
      handler: (res) => {
        paymentVerificationSave(res);
        return res;
      },
      prefill: {
        name: asset.name,
        email: asset.mail,
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handleFundTransferUserToCreator = async () => {
    var datx = {
      credit: {
        name: data?.creator?.name || "******",
        mail: data?.creator?.mail || "******",
        item: data?.creator?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: {
        name: `${data?.meta?.name || "sale"}`,
        memo: `${data?.meta?.name || "ticket"} sale`,
      },
      count: { number: "1", ticker: "" },
      rate: {
        number: rates.find((x) => x.status)?.rate?.nmbr || "0",
        ticker: "INR",
      },
      asset: data.asset || {},
      unit: data.unit || {},
    };
    var res = await TransfersFundCreate({
      data: datx,
      client: "",
      service: "",
    });
   

    // 6: fund transfer submit
    datx = {
      user: asset?.item || "******",
      item: res?.data?.item || "******",
    };
    res = await TransfersFundSubmit({ data: datx, client: "", service: "" });

    if (res.stat) {
      setCommerce(true);
      handleAssetTransferCreate();
      var res = await IndividualOffersStatusSet({
        data: { item: od, active: false },
        client: "",
        service: "",
      });
      setModalCommerceStatus(false);
      // setReset(true)
      // modalCommerceClose()
      setModalMinterStatus(true);
    }
  };

  const handleFundTransferUserToSeller = async () => {
    var datx = {
      credit: {
        name: checkoutitem?.user?.name || "******",
        mail: checkoutitem?.user?.mail || "******",
        item: checkoutitem?.user?.item || "******",
      },
      debit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      meta: {
        name: `${data?.meta?.name || "sale"}`,
        memo: `${data?.meta?.name || "ticket"} sale`,
      },
      count: { number: "1", ticker: "" },
      rate: {
        number: rates.find((x) => x.status)?.rate?.number || "0",
        ticker: "INR",
      },
      asset: data.asset || {},
      unit: data.unit || {},
    };
    var res = await TransfersFundCreate({
      data: datx,
      client: "",
      service: "",
    });
    // console.log(res)

    // 6: fund transfer submit
    datx = {
      user: asset?.item || "******",
      item: res?.data?.item || "******",
    };
    res = await TransfersFundSubmit({ data: datx, client: "", service: "" });

    if (res.stat) {
      setCommerce(true);
      handleAssetTransferCreate();
      setTimeout(() => {
        setModalCommerceStatus(false);
      }, 3000);
      setModalMinterStatus(true);
    }
  };

  const handleAssetTransferCreate = async () => {
    //asset transfer create
    var debit = {
      name: data.creator?.name || "******",
      mail: data.creator?.mail || "******",
      item: data.creator?.item || "******",
    };

    if (checkoutitem?.feat?.mode == "resale") {
      debit = {
        name: checkoutitem?.user?.name || "******",
        mail: checkoutitem?.user?.mail || "******",
        item: checkoutitem?.user?.item || "******",
      };
    }

    const offerx = rates.find((x) => x.status);
    var datx = {
      credit: {
        name: asset?.name || "******",
        mail: asset?.mail || "******",
        item: asset?.item || "******",
      },
      debit: debit,
      meta: {
        name: `${data?.meta?.name || "sale"}`,
        memo: `${data?.meta?.name || "ticket"} sale`,
      },
      count: { number: "1", ticker: "IAM" },
      rate: {
        number: offerx?.rate?.number || "0",
        ticker: offerx?.rate?.ticker || "******",
      },
      asset: data.asset.item || "",
      unit: data.item || "",
      format: type,
      mode: checkoutitem?.feat?.mode == "resale" ? "resale" : "sale",
      crxx: checkoutitem?.cred ? checkoutitem.cred : null,
    };

    var result = await TransfersAssetClaim({
      data: datx,
      client: "",
      service: "",
    });

    if (result.stat) {
      var res = await IndividualOffersStatusSet({
        data: { item: od, active: false },
        client: "",
        service: "",
      });
      //console.log(result.data.item)
      setMemo(
        "Asset claim successful. Please check the asset section after 30 seconds."
      );
      setMinterStatus(true);

      if (checkoutitem.meta.name == "General") {
      }
      if (checkoutitem.meta.name != "General") {
        // sent offer actv to false
        // var res = await AssetOfferStatusSet({data:{item: checkoutitem.item, active: false}, client:'', service:'' })
        // console.log(res)
      }
      setMintDone(true);
      setTimeout(() => {
        setModalMinterStatus(false);
        modalMinterClose();
        navigate("/user/home");
      }, 5000);

      setModalCheckoutStatus(false);

      // props.done(true)

      // setAssetTrxnId(result.data.item);
    }

    if (!result.stat) {
      setMemo("Failed: Asset Transfer Not Created");
      return;
    }
  };

  const handleUnitTransfer = async () => {
    setMintStart(true);
    setMemo("Please Wait...");
    handleAssetTransferCreate();
  };

  const modalMinterShow = () => {
    setModalMinterStatus(true);
  };
  const modalMinterClose = () => {
    setModalMinterStatus(false);
  };

  const modalShow = (item) => {
    // item.rate.number=0

    // console.log(item)
    if (item?.rate?.nmbr == "0" || item?.rate?.number == "0") {
      // handleAssetTransferCreate()
      setModalMinterStatus(true);
    } else {
      setModalStatus(true);
    }

    //
  };
  const modalClose = () => {
    setModalStatus(false);
  };

  if (loader)
    return (
      <>
        <div
          className="p-3 back-color-wite rounded-xd border align-middle"
          style={{}}
        >
          <span className="align-middle text-lead">
            <i className="bx bxs-info-circle text-color-wait"></i>
          </span>
          <span className="ms-1 text-color-tone">Please Wait...</span>
        </div>
      </>
    );

  return (
    <>
      {/* data */}
      <div className="p-1 back-color-wite rounded-xd">
        <div className="media-standard rounded-xd">
          <img
            className="w-100 rounded-xd shadow"
            src={data?.media?.link}
            alt="..."
          ></img>
        </div>

        <div className="mx-3">
          <WebbDividerSmall />
          <p className="text-normal text-bold m-0 text-truncate">
            {data?.meta?.name}
          </p>
          <p className="text-small m-0 mb-2">{data?.number}</p>

          <p className="m-0 text-wd">{data?.meta?.memo}</p>
        </div>

        <WebbDividerSmall />
        <div className="mx-3">
          <span className="text-small m-0">{data?.creator?.name}</span>
        </div>

        <WebbDividerSmall />
      </div>

      {/* actions */}

      {/* rates */}
      <WebbDividerMedium />
      <div className={""}>
        <p className="text-bold m-0 mx-3 mb-2">Available Offer(s)</p>
        <div
          className={
            rates?.length > 0
              ? "back-color-wite border rounded-xd p-1"
              : "d-none"
          }
        >
          {rates &&
            rates?.map((item, x) =>
              item.active ? (
                <div className="" key={x}>
                  <div
                    className="d-flex m-0 p-2 rounded-wd cursor hidark"
                    onClick={() => {
                      setAction("checkout");
                      setCheckoutItem(item);
                      modalShow(item);
                      handleUserOfferSelect(item.user);
                    }}
                  >
                    <div
                      className={` ${
                        item.status ? "text-color-success" : "text-color-tint"
                      }`}
                    >
                      <span className="text-normal align-middle d-none">
                        <i
                          className={`${
                            item.status
                              ? "bx bxs-check-circle"
                              : "bx bxs-circle"
                          }`}
                        ></i>
                      </span>
                    </div>
                    <div className="">
                      <p className="m-0">{item?.meta?.name || "******"}</p>
                      <p className="m-0 text-small d-none">
                        {item?.number || "******"}
                      </p>
                    </div>
                    <div className="ms-auto text-end">
                      <span className="">{item?.rate?.nmbr || "******"}</span>
                      <span className="ms-1"></span>
                      <span className="text-small text-uppercase">
                        {item?.rate?.tick || "******"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      x < rates.length - 1
                        ? "border-bottom border-light mx-2"
                        : ""
                    }
                  ></div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
      </div>

      {/* rates - others */}
      <div className={offers?.length > 0 ? "d-none" : "d-none"}>
        <WebbDividerSmall />

        <p className="m-0 mx-3 mb-2">Other Offer(s): {offers?.length || 0}</p>
        <div
          className={
            offers?.length > 0
              ? "back-color-wite border rounded-xd p-1"
              : "d-none"
          }
        >
          {rates &&
            offers?.map((item, x) =>
              item.active && item.user.mail !== asset.mail ? (
                <div className="m-0" key={x}>
                  <div
                    className="d-flex p-2 m-0 rounded-wd cursor hidark"
                    onClick={() => {
                      setAction("checkout");
                      setCheckoutItem(item);
                      modalShow(item);
                      handleAlternateOfferSelect(item.user.item);
                    }}
                  >
                    <div
                      className={`d-none ${
                        item.status ? "text-color-success" : "text-color-tint"
                      }`}
                    >
                      <span className="text-normal align-middle">
                        <i
                          className={`${
                            item.status
                              ? "bx bxs-check-circle"
                              : "bx bxs-circle"
                          }`}
                        ></i>
                      </span>
                    </div>
                    <div className="">
                      <p className="m-0">{item?.meta?.name || "******"}</p>
                      <p className="m-0 text-small d-none">
                        {item?.number || "******"}
                      </p>
                    </div>
                    <div className="ms-auto text-end">
                      <span className="">{item?.rate?.number || "******"}</span>
                      <span className="ms-1"></span>
                      <span className="text-small text-uppercase">
                        {item?.rate?.ticker || "******"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={
                      x < rates.length - 1
                        ? "border-bottom border-light mx-2"
                        : ""
                    }
                  ></div>
                </div>
              ) : (
                ""
              )
            )}

          {/* offers - alt */}
        </div>
      </div>

      {/* actions */}
      <div className="mb-1"></div>
      <div className={modalstatus && action == "checkout" ? "" : "d-none"}>
        <div className="modal-overlay" id="">
          <div className="modal-content rounded-xd">
            {/* modal header */}
            <div className="p-3 m-0 d-none">
              <div className="header text-bold m-0"></div>
            </div>

            {/* modal content */}
            <div className="border-none scrollbar">
              {" "}
              {/* style={{overflowY:'scroll', height:'auto'}} */}
              <div className="border-none p-3 mx-3 rounded-xd">
                <div className="text-center">
                  <p className="text-normal m-0">{"Get Offer"}</p>

                  <div className="mb-2"></div>
                  <p className="text-normal text-bold m-0 text-truncate">
                    {data?.meta?.name || "******"}
                  </p>
                  <p className="text-small m-0 d-none">
                    {data?.webx?.number || "******"}
                  </p>
                </div>

                <div className="">
                  <div className="mb-2"></div>
                  <div className="media-banner">
                    <img
                      src={data?.media?.link}
                      className="w-100 rounded-xd"
                      alt="..."
                    ></img>
                  </div>
                </div>

                <div className="text-center">
                  <WebbDividerSmall />
                  <p className="m-0">Order Value</p>
                  <p className="text-bold m-0">
                    <span className="text-header-sm">
                      {checkoutitem?.rate?.nmbr || "0"}
                    </span>
                    <span className="ms-1"></span>
                    <span className="text-small text-uppercase">
                      {checkoutitem?.rate?.tick || "0"}
                    </span>
                  </p>
                  <p className="m-0 d-none">
                    {balance.number / 1000000 || "0"}
                  </p>
                </div>

                <div className={balancestatus ? "d-none" : "d-none"}>
                  <label className="form-label text-small">
                    Enter Account Passcode
                  </label>
                  <input
                    type="text"
                    className="form-control height-md text-center"
                    style={{ fontSize: "0.9rem", height: "2.7rem" }}
                    value={code}
                    onChange={({ target }) => {
                      setCode(target.value);
                    }}
                    disabled={loader || submit}
                    placeholder="123456"
                  />
                  <WebbDividerMedium />
                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className={loader || submit || done ? "d-none" : ""}>
                  <button
                    className="btn btn-primary btn-sm text-small rounded-xx w-100"
                    onClick={() => handleCheckoutUserOffer()}
                    disabled={loader || submit}
                  >
                    Checkout
                  </button>

                  <div className="mb-1"></div>
                  <button
                    className="btn btn-outline-secondary  border btn-sm text-small rounded-xx w-100"
                    onClick={() => modalClose()}
                  >
                    Cancel
                  </button>
                </div>

                <div className={!loader && done ? "text-center" : "d-none"}>
                  <p className="m-0">success: asset minted</p>

                  <WebbDividerSmall />
                  <button
                    className="btn btn-outline-secondary border btn-sm text-small rounded-xx w-100"
                    onClick={() => modalClose()}
                  >
                    Close
                  </button>
                </div>

                <WebbDividerSmall />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal - ecom */}
      <div className={modalstatus && action == "ecommerce" ? "" : "d-none"}>
        <div
          className="modal-overlay"
          id=""
          onClick={() => modalTransactionOpen()}
        >
          <div className="modal-content rounded-xd">
            {/* modal header */}
            <div className="p-3 m-0 d-none">
              <div className="header text-bold m-0"></div>
            </div>

            {/* modal content */}
            <div className="border-none scrollbar">
              {" "}
              {/* style={{overflowY:'scroll', height:'auto'}} */}
              <div className="border-none p-3 mx-3 rounded-xd">
                <div className="text-center">
                  <p className="text-normal m-0">{"Complete Payment"}</p>

                  <div className="mb-2"></div>
                  <p className="text-normal text-bold m-0 text-truncate">
                    {data?.meta?.name || "******"}
                  </p>
                  <p className="text-small m-0 d-none">
                    {data?.webx?.number || "******"}
                  </p>
                </div>

                <div className="">
                  <div className="mb-2"></div>
                  <div className="media-banner">
                    <img
                      src={data?.media?.link}
                      className="w-100 rounded-xd"
                      alt="..."
                    ></img>
                  </div>
                </div>

                <div className="text-center">
                  <WebbDividerSmall />
                  <p className="m-0">Order Value</p>
                  <p className="text-bold m-0">
                    <span className="text-header-sm">
                      {checkoutitem?.rate?.nmbr || "0"}
                    </span>
                    <span className="ms-1"></span>
                    <span className="text-small text-uppercase">
                      {checkoutitem?.rate?.tick || "0"}
                    </span>
                  </p>
                  <p className="m-0 d-none">
                    {balance.number / 1000000 || "0"}
                  </p>
                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className="text-center">
                  <p className="m-0">Please Wait...</p>
                </div>

                {/* success */}
                <div className={`mb-3 text-center ${commerce ? "" : "d-none"}`}>
                  <Lottie options={defaultOptions} height={200} width={200} />
                </div>
                <WebbDividerMedium />
                <WebbDividerSmall />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal - ecom - cancel */}
      <div className={`container ${modalCancelTransaction ? "" : "d-none"}`}>
        <div className="row">
          <div className="col">
            <div className="modal-overlay">
              <div
                className="modal-content rounded-xd"
                onClick={(e) => e.stopPropagation()}
              >
                {/* modal header */}

                {/* modal content */}
                <div className={`modal-body border-none p-3`}>
                  <div className="text-center">
                    <p className="text-normal m-0">{"Cancel Transaction"}</p>
                  </div>
                  <p className="text-center mt-3">
                    Are You Sure to Cancel Transaction
                  </p>
                  <div className="mt-5">
                    <div className="d-flex">
                      <div className="me-auto">
                        <button
                          onClick={() => modalTransactionClose()}
                          className="btn btn-outline-danger rounded-xx"
                        >
                          Yes
                        </button>
                      </div>
                      <div className="text-end">
                        <button
                          className="btn btn-outline-success rounded-xx"
                          onClick={() => {
                            ContinueOrderPurchase();
                          }}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>

                  <WebbDividerSmall />
                </div>

                {/* action */}
                <WebbDividerMedium />
                <div className={`p-3 ${commerce ? "d-none" : "d-none"}`}>
                  <div className="mb-2"></div>
                  <button
                    className="btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100"
                    onClick={() => modalTransactionClose()}
                  >
                    {"Cancel"}
                  </button>
                </div>

                {/* footer */}
                <WebbDividerSmall />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal - minter */}
      <div className={`container ${modalMinterStatus ? "" : "d-none"}`}>
        <div className="row">
          <div className="col">
            <div className="modal-overlay">
              <div
                className="modal-content rounded-xd"
                onClick={(e) => e.stopPropagation()}
              >
                {/* modal header */}
                <div className=""></div>

                {/* modal content */}
                <div className="modal-body border-none p-3">
                  <div className="text-center">
                    <p className={`text-normal m-0`}>{"Mint Asset"}</p>
                  </div>

                  <WebbDividerSmall />
                  <div className="media-banner">
                    <img
                      className="rounded-xd"
                      src={data?.media?.link || "******"}
                      alt="..."
                    ></img>
                  </div>

                  <WebbDividerSmall />
                  <div className={`text-center`}>
                    <p className="text-small m-0 d-none">{""}</p>
                    <p className="text-lead text-bold m-0">
                      {data?.meta?.name || "******"}
                    </p>
                    <p className="m-0">ID: {data?.webx?.number || "******"}</p>
                  </div>

                  {/* <div className={`text-center ${mintstart && minterstatus ? 'd-none' : ''}`}>
                    <WebbDividerMedium />
                    <label className="form-label text-small">Enter Your Minter Account Passcode</label>
                    <input type="text" 
                      className="form-control height-md text-center"
                      style={{fontSize: '0.9rem', height: '2.7rem'}}
                      pattern="[0-9][0-9]*"
                      value={secret}
                      onChange={({ target }) => {{ 
                        setSecret(target.validity.valid 
                          ? target.value : secret ); 
                      };setMemo('') }}
                      disabled={minterstatus}
                      placeholder={''} 
                    />
                    <p className={`mt-1 mb-0 text-color-error text-small ${mintstart  && minterstatus == false? '' : 'd-none'}`}>{memo}</p>
                  </div> */}
                </div>

                {/* <p className={mintstart ? "text-center text-small m-0" :'d-none'}>{memo}</p> */}

                {/* action minter */}
                <div
                  className={`p-3 ${
                    minterstatus && mintstart && !mintdone ? "" : "d-none"
                  }`}
                >
                  <WebbDividerMedium />
                  <p className="text-center text-small m-0">{memo}</p>
                  <div className="mb-2"></div>
                  <p className="text-center text-color-error m-0">
                    {"Do not close the window"}
                  </p>

                  <WebbDividerSmall />
                  <div
                    className="progress"
                    role="progressbar"
                    aria-label="..."
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "100%", height: "0.5rem" }}
                  >
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      style={{ width: "100%", height: "0.5rem" }}
                    ></div>
                  </div>
                </div>
                <div className={`p-3 ${mintstart && mintdone ? "" : "d-none"}`}>
                  <p className="text-center text-bold m-0">
                    {"Congratulations"}
                  </p>
                  <WebbDividerSmall />

                  <Lottie options={defaultOptions} height={200} width={200} />
                  <p className="text-center m-0">{memo}</p>
                </div>

                <div
                  className={`p-3 ${mintstart && minterstatus ? "d-none" : ""}`}
                >
                  <button
                    className="btn btn-primary text-small rounded-xx text-nowrap w-100"
                    disabled={submit || done}
                    onClick={() => {
                      handleUnitTransfer();
                    }}
                  >
                    {"Mint Now"}
                  </button>
                  <div className="mb-2"></div>
                  <button
                    className="btn btn-outline-secondary border text-small rounded-xx text-nowrap w-100"
                    onClick={() => {
                      modalMinterClose();
                      // navigate("/user/assets");
                    }}
                  >
                    {"Skip"}
                  </button>
                  <WebbDividerSmall />
                </div>

                {/* modal footer */}
                {/* <div className="text-center text-small text-primary ">{memo}</div>                 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
