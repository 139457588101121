// routes
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/authcontext";

// views - main
import Main from "../views.xz/main-xz";


// views - home
import HomeUser from "../views.home/home-user";


// views - assets
import Assets from "../views.assets/assets";
import AssetDetailsUser from "../views.assets/asset-details-user";
import AssetDetailsUserIdentiti from "../views.assets/asset-details-user-id";

import CredsListUser from "../views.assets/creds-list";

// views - transfers
import Transfers from "../views.transfers/transfers";


// views - transfers
import Discover from "../views.discover/discover";
import DiscoverDetails from "../views.discover/discover-details";


// views - user
import UserDetails from "../views.user/user-details";
import UserShareDefault from "../views.user/user-share-default";

// views - accounts


// views - documents


// views - scan
import Scan from "../views.scan/scan";

// views - roooms
import Rooms from "../views.rooms/rooms";

// views - onboard
import UserOnboardName from '../views.onboard/user-onboard-name'
import UserOnboardIdentiti from "../views.onboard/user-onboard-id";
import UserOnboardMinter from "../views.onboard/user-onboard-minter";
import UserOnboardHold from "../views.onboard/user-onboard-hold";

// views - auth
import AuthMailCode from "../views.auth/auth-mail-code";
import AuthNext from "../views.auth/auth-next";
import AuthSessionX from "../views.auth/auth-session-x";
import DocumentCreate  from "../views.documents/documents-create";
import OffersDetails from "../views.offers.js/offers-details";
import DocumentsDetailsUser from "../views.documents/documents-details";
import UserScanProfile from "../views.user/user-scan-detail";
import AuthMobileCode from "../views.auth/auth-mobile-code";


const routes = [
  { route: "/", content: <Main />, auth: false },

  // user
  { route: "/user/home", content: <HomeUser />, auth: true },

  // markets

  // assets
  { route: "/user/assets", content: <Assets />, auth: true },
  {
    route: "/user/assets/:type/:id",
    content: <AssetDetailsUser />,
    auth: true,
  },
  {
    route: "/user/assets/id/:id",
    content: <AssetDetailsUserIdentiti />,
    auth: true,
  },
  { route: "/user/assets/cx/:id", content: <CredsListUser />, auth: true },

  // tokens

  // network

  // offers
  { route: "/user/offers/:od/:id", content: <OffersDetails />, auth: true },

  // transfers
  { route: "/user/transfers", content: <Transfers />, auth: true },

  // transfers - assets

  // transfers - funds

  // discover
  { route: "/user/discover", content: <Discover />, auth: true },
  {
    route: "/user/discover/:type/:id",
    content: <DiscoverDetails />,
    auth: true,
  },

  // scan
  { route: "/user/scan", content: <Scan />, auth: true },

  // accounts

  // documents
  { route: "/user/document/upload", content: <DocumentCreate />, auth: true },
  {
    route: "/user/documents/:id",
    content: <DocumentsDetailsUser />,
    auth: true,
  },

  // rooms
  { route: "/user/rooms", content: <Rooms />, auth: true },

  // user
  { route: "/user/account", content: <UserDetails />, auth: true },
  { route: "/user/share/default", content: <UserShareDefault />, auth: true },
  { route: "/ux/profile/:id", content: <UserScanProfile />, auth: true },

  // onboard
  { route: "/user/onboard", content: <UserOnboardName />, auth: true },
  { route: "/user/onboard/id", content: <UserOnboardIdentiti />, auth: true },
  { route: "/user/onboard/minter", content: <UserOnboardMinter />, auth: true },
  { route: "/user/onboard/hold", content: <UserOnboardHold />, auth: true },

  // auth
  { route: "/auth", content: <AuthMailCode />, auth: false },
  { route: "/auth/mobile", content: <AuthMobileCode />, auth: false },
  { route: "/auth/next", content: <AuthNext />, auth: true },
  { route: "/auth/x", content: <AuthSessionX />, auth: true },
];


export default function RouteX() {

  const { user } = useAuth();

  return (
    <Routes>
      {routes.map ((item,i)=>(item.auth
        ? <Route key={i} path={item.route} element={!user ? <Navigate to='/' replace /> : item.content} />
        : <Route key={i} path={item.route} element={item.content} />
      ))}
    </Routes>
  );
}